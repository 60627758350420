const products = () => {
  const viewport = $(window).width();
  if (viewport < 991) {
    $('#sticky-wp').removeClass('show');
  } else {
    $('#sticky-wp').addClass('show');
  }
};

const navigationMobile = () => {
  const SEARCH = 'SEARCH';
  const MENU = 'MENU';
  const handleCloseItem = (type) => {
    if (type === SEARCH) {
      $('body').removeClass('search-open');
      $('#button--search .icon-search').text('search');
    } else {
      $('body').removeClass('menu-open');
      $('#navigation-main').hide();
    }
  };
  $('#button--mobile').on('click', () => {
    $('body').toggleClass('menu-open');
    $('#navigation-main').toggle();
    handleCloseItem(SEARCH);
  });
  $('#button--search').on('click', (e) => {
    handleCloseItem(MENU);
    const isOpen = $('body').hasClass('search-open');
    $('body').toggleClass('search-open');
    $(e.currentTarget).find('.icon-search').text(isOpen ? 'search' : 'close');
  });
};

const navigationSize = () => {
  const headerTopHeight = $('#header-top').innerHeight();
  const headerBottomHeight = $('#header-bottom').innerHeight();
  const applyPadding = () => {
    const isMenuFixed = $('body').hasClass('header-menu-fixed');
    $('body').css('paddingTop', isMenuFixed ? headerBottomHeight : 0);
  };
  const position = $(window).scrollTop();
  if (position > headerTopHeight) {
    $('body').addClass('header-menu-fixed');
  } else {
    $('body').removeClass('header-menu-fixed');
  }
  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > headerTopHeight) {
      $('body').addClass('header-menu-fixed');
    } else {
      $('body').removeClass('header-menu-fixed');
    }
    applyPadding();
  });
};

const navigationDropdown = () => {
  const viewport = $(window).width();
  if (viewport < 576) {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      $(e.currentTarget).next().toggle();
      $(e.currentTarget).toggleClass('open');
      return false;
    });
  } else {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      const navSub = $(e.currentTarget).parent();
      const isFocus = navSub.hasClass('focus');
      $('#navigation-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      e.stopPropagation();
      return false;
    });
    $('html').on('click', () => {
      $('#navigation-main li[data-navsub]').removeClass('focus');
    });
  }
};

const scrollTop = () => {
  const domElm = $('#button--scroll-top');
  domElm.fadeOut(0);
  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > 220) {
      domElm.fadeIn(300);
    } else {
      domElm.fadeOut(300);
    }
  });
  domElm.on('click', () => {
    $('html, body').animate({
      scrollTop: 0,
    }, '500');
    return false;
  });
};

navigationMobile();
navigationDropdown();
navigationSize();
scrollTop();
products();
