import 'ion-rangeslider';
import 'ion-rangeslider/css/ion.rangeSlider.min.css';
import {f_filter} from "../pages/recherche";

$('#range-wrapper').ionRangeSlider({
  type: 'double',
  min: 0,
  max: $(this).attr('data-max'),
  from: 0,
  postfix: '€',
  to: $(this).attr('data-max'),
  skin: 'round',
  onFinish: function (data) {
      f_filter();
  },
});

