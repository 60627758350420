import 'sticky-kit/dist/sticky-kit';

const stickykitInit = () => {
  const viewport = $(window).width();
  if (viewport > 991) {
    $('#sticky-wp').stick_in_parent({
      recalc_every: 1,
      offset_top: $('#header-bottom').innerHeight(),
    });
  } else {
    $('#sticky-wp').trigger('sticky_kit:detach');
  }
};

stickykitInit();

$(window).on('resize', () => {
  stickykitInit();
});
