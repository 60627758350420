import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

const productsSettings = {
  slidesToShow: 4,
  slidesToScroll: 2,
  dots: false,
  fade: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: false,
  cssEase: 'ease-in-out',
  speed: 400,
  centerMode: false,
  autoplaySpeed: 2000,
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-alt-left"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-alt-right"></span></button>',
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

$('#slick-logos').slick({
  slidesToShow: 8,
  slidesToScroll: 2,
  dots: false,
  fade: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  centerMode: false,
  autoplaySpeed: 2000,
  appendArrows: '#slick-logos-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-small-left mr-15"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-small-right ml-15"></span></button>',
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
});

$('#slick-products-blue').slick({
  ...productsSettings,
  appendArrows: '#slick-products-blue-arrows',
});

$('#slick-products-green').slick({
  ...productsSettings,
  appendArrows: '#slick-products-green-arrows',
});

$('#slick-products-green').slick('slickPause');

setTimeout(() => {
  $('#slick-products-green').slick('slickPlay');
}, 1000);

$('#slick-highlight').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: false,
  infinite: true,
  arrows: true,
  autoplay: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 150,
  centerMode: false,
  autoplaySpeed: 2000,
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-regular-left"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-regular-right"></span></button>',
});

setTimeout(() => {
  $('#slick-highlight .slick-active').addClass('animation');
}, 250);

$('#slick-highlight').on('afterChange', () => {
  $('#slick-highlight .slick-active').addClass('animation');
});

$('#slick-highlight').on('beforeChange', () => {
  $('#slick-highlight .slick-active').removeClass('animation');
});

$('#slick-product').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  fade: false,
  arrows: false,
  autoplay: true,
  adaptiveHeight: false,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [{
    breakpoint: 1200,
    settings: {},
  },
  {
    breakpoint: 991,
    settings: {},
  },
  {
    breakpoint: 576,
    settings: {
      autoplay: false,
    },
  },
  ],
});

$('#slick-product-nav .button').on('click', function () {
  const number = $(this).attr('data-trigger');
  $('#slick-product').slick('slickGoTo', parseInt(number - 1), false);
});
