import select2 from 'select2';
import 'select2/dist/css/select2.css';

window.select2 = select2();

$('.selectpicker').select2({
  language: 'fr',
  minimumResultsForSearch: -1,
  placeholder: 'Sélectionner le service souhaité',
  width: '100%',
});
